import { useContext, useEffect, useState } from "react"
import { useParams, useHistory, NavLink } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Badge } from "primereact/badge"
import { Divider } from "primereact/divider"
import { Checkbox } from "primereact/checkbox"
import moment from "moment"
import { useImmer } from "use-immer"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useSubTaskCauses } from "../../../api/queries/useSubTaskCauses"
import commonStyles from "../../common.module.css"
import FileUpload from "../../File/FileUpload"
import { useTaskTags } from "../../../api/queries/useTaskTags"
import getRevisionSubTaskStateSeverity from "../../../function/getRevisionSubTaskStateSeverity"
import getRevisionSubTaskStateName from "../../../function/getRevisionSubTaskStateName"
import { InputTextarea } from "primereact/inputtextarea"
import styles from "./styles.module.css"
import { Calendar } from "primereact/calendar"

export default function TaskPreventionRevisionSubTaskForm() {
  const { uuid } = useParams()
  const { user } = useContext(userContext)
  const history = useHistory()
  const { hasRole } = useContext(userContext)
  const back = () => history.goBack()
  const backToForm = () => history.push(`/task/prevention/${uuid}`)
  const { data: taskTags = [] } = useTaskTags()
  const zavadaTag = taskTags.find((taskTag) => taskTag.key === "zavada")
  const { data: subTaskCauses = [] } = useSubTaskCauses()

  const verificationMethods = [
    { label: "Vizuálně", value: 1 },
    { label: "Měřením", value: 2 },
  ]

  const [task, setTask] = useState({
    machine: { name: "" },
  })
  const [refresher, setRefresher] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [originalFileUuids, setOriginalFileUuids] = useState([])

  const [supportDoc, setSupportDoc] = useState(false)
  const [operationalDoc, setOperationalDoc] = useState(false)
  const [defects, setDefects] = useState("")
  const [fulfill, setFulfill] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [job, setJob] = useState("")
  const [date, setDate] = useState(new Date())

  const onSupportDocChange = (event) => setSupportDoc(event.checked)
  const onOperationalDocChange = (event) => setOperationalDoc(event.checked)
  const onDefectsChange = (event) => setDefects(event.target.value)
  const onFulfillChange = (event) => setFulfill(event.checked)
  const onFirstnameChange = (event) => setFirstname(event.target.value)
  const onLastnameChange = (event) => setLastname(event.target.value)
  const onJobChange = (event) => setJob(event.target.value)
  const onDateChange = (event) => setDate(event.value)

  const [subTaskOpen, setSubTaskOpen] = useState({})
  const [subTaskOpenState, setSubTaskOpenState] = useState("")
  const [subTaskOpenCause, setSubTaskOpenCause] = useState(null)
  const [subTaskOpenReportName, setSubTaskOpenReportName] = useState("")
  const [subTaskOpenReportDesc, setSubTaskOpenReportDesc] = useState("")
  const [subTaskOpenReportFiles, setSubTaskOpenReportFiles] = useState([])

  const handleSubTaskOpenReportNameChange = (e) => setSubTaskOpenReportName(e.target.value)
  const handleSubTaskOpenReportDescChange = (e) => setSubTaskOpenReportDesc(e.target.value)

  const openSubTask = (subTask, state) => {
    setSubTaskOpen(subTask)
    setSubTaskOpenState(state)
    setSubTaskOpenReportDesc("")

    if (state === "FINISHED") {
      setSubTaskOpenCause("FAULT")
    } else {
      setSubTaskOpenCause("")
    }
  }

  const closeSubTask = () => {
    setSubTaskOpen({})
    setSubTaskOpenCause(null)
    setSubTaskOpenState("")
    setSubTaskOpenReportName("")
    setSubTaskOpenReportDesc("")
    setSubTaskOpenReportFiles([])
  }

  const [relatedTasks, setRelatedTasks] = useState([])
  const [subTasks, setSubTasks] = useImmer([])

  const handleSubTaskChange = (subTask, key, value) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)
      draftSubTask[key] = value
    })
  }

  const setSubTaskFiles = async (subTask, files) => {
    if (subTask.files.length > files.length) {
      // some file was deleted
      let deletedFileUuid = null

      subTask.files.forEach((subTaskFile) => {
        const file = files.find((file) => subTaskFile.uuid === file.uuid)

        // async delete only for existing files (they have url property)
        if (subTaskFile.url && file === undefined) {
          deletedFileUuid = subTaskFile.uuid
        }
      })

      if (deletedFileUuid) {
        await api.delete(`/tasks/${uuid}/sub/${subTask.uuid}/file/${deletedFileUuid}`)
      }
    }

    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)
      draftSubTask.files = [...files]
    })
  }

  const updateSubtask = async (subTask, state) => {
    setIsLoading(true)

    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })

    const params = {
      uuid: subTask.uuid, state, state_description: subTask.state_description,
      files: subTask.files,
    }

    if (subTaskOpenCause) {
      params.cause_of_unfinished = subTaskOpenCause
    }

    if (subTask.value) {
      params.value = subTask.value
    }

    await api.post(`/tasks/${uuid}/sub/${subTask.uuid}`, params)

    // new files
    const files = subTask.files.filter((file) => !file.createdBy)

    for (let i = 0; i < files.length; i++) {
      const file = Object.assign({}, files[i])
      delete file.uuid
      await api.put(`/tasks/${uuid}/sub/${subTask.uuid}/file`, file)
    }

    setRefresher(!refresher)
    setIsLoading(false)
    closeSubTask()
  }

  const reopenSubtask = async (subTask) => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })
    await api.post(`/tasks/${uuid}/sub/${subTask.uuid}`, {
      uuid: subTask.uuid,
      state: "UNKNOWN",
    })
    setRefresher(!refresher)
  }

  const finishPrevention = async () => {
    let state = "FINISHED"

    if (subTasks.some((subTask) => subTask.state === "NOT_FINISHED")) {
      state = "PARTIALY_FINISHED"
    }

    if (subTasks.some((subTask) => subTask.state === "UNKNOWN")) {
      state = "NOT_FINISHED"
    }

    let revision = {
      supportDoc,
      operationalDoc,
      defects,
      fulfill,
      firstname,
      lastname,
      job,
      date: moment(date).format("YYYY-MM-DD"),
    }

    await api.post(`/tasks/${uuid}`, { uuid, state, revision })
    setRefresher(!refresher)
  }

  const createReport = async (subTask) => {
    const files = subTaskOpenReportFiles.map((file) => {
      const newfile = Object.assign({}, file)
      delete newfile.uuid
      return newfile
    })

    const resp = await api.put("/tasks", {
      type: "REPORT",
      operation: user.operation.uuid,
      team: task.team.uuid,
      machine: task.machine.uuid,
      starts_at: moment().toISOString(), // now
      name: subTaskOpenReportName,
      description: subTaskOpenReportDesc,
      files,
      tag: subTaskOpenCause === "FAULT" ? zavadaTag.uuid : "",
    })
    await api.post(`/tasks/${uuid}/related`, {
      uuid: resp.data.data.uuid,
      subtask_uuid: subTask.uuid,
      is_child: false
    })
    await updateSubtask(subTask, subTaskOpenState)
  }

  const getSubTaskBadgeValue = (subTask) => {
    const subTaskStateName = getRevisionSubTaskStateName(subTask.state)
    const subTaskCause = subTaskCauses.find((cause) => cause.value === subTask.cause_of_unfinished)

    if (subTaskCause) {
      return `${subTaskStateName} (${subTaskCause.name})`
    }

    return subTaskStateName
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
          setRelatedTasks(data.data.related)
          setSubTasks((draft) => {
            draft.splice(0, draft.length)
            data.data.tasks.forEach((subTask) => draft.push(subTask))
          })

          // handle original files from prevention, this should not be deleted by user
          const preventionFileUuids = []

          data.data.tasks.forEach((subTask) => {
            subTask.files.forEach((file) => {
              preventionFileUuids.push(file.uuid)
            })
          })

          setOriginalFileUuids(preventionFileUuids)

          if (data.data.revision) {
            const revision = data.data.revision

            if (revision.supportDoc) {
              setSupportDoc(revision.supportDoc)
            }

            if (revision.operationalDoc) {
              setOperationalDoc(revision.operationalDoc)
            }

            if (revision.defects) {
              setDefects(revision.defects)
            }

            if (revision.fulfill) {
              setFulfill(revision.fulfill)
            }

            if (revision.firstname) {
              setFirstname(revision.firstname)
            }

            if (revision.lastname) {
              setLastname(revision.lastname)
            }

            if (revision.job) {
              setJob(revision.job)
            }

            if (revision.date) {
              setDate(moment(revision.date).toDate())
            }
          }
        })
    }
  }, [uuid, refresher, setSubTasks])

  const renderRelatedSubTaskDetail = (uuid) => {
    const subTaskRelatedTasks = relatedTasks.filter((relatedTask) => relatedTask.related_subtask_uuid === uuid)

    if (subTaskRelatedTasks.length > 0) {
      return (
        <div className={"pb-3"}>
          <label className="text-500">Existující hlášení:</label>
          {" "}
          {subTaskRelatedTasks.map((r, i) => (
            <>
              <NavLink
                to={`/task/report/${r.uuid}/detail`}
                className={"text-primary"}
                target="_blank"
              >
                #{r.id} - {r.name}
              </NavLink>

              {i < subTaskRelatedTasks.length - 1 && <>, </>}
            </>
          ))}
        </div>
      )
    } else {
      return <></>
    }
  }

  const isPreventionDisabled = task.state !== "IN_PROGRESS" ||
    subTasks.some((subTask) => subTask.state === "UNKNOWN");

  const showExport = task.state !== "IN_PROGRESS" &&
    subTasks.every((subTask) => subTask.state !== "UNKNOWN");

  const someSubtaskIsNotFinished = subTasks.some((subTask) => subTask.state === "NOT_FINISHED")

  const onExport = async () => {
    try {
      const { data } = await api.get(`/tasks/${uuid}/revision`)
      window.open(data.data.url)
    } catch (err) { }
  }

  return (
    <div className={"col-12 md:col-6"}>
      <div className={"p-fluid"}>
        <h1 style={{ textAlign: "center" }}>{task.machine.name}</h1>
        <h2>Kontrola minimálních požadavků na bezpečný provoz zařízení dle NV č. 378/2001 Sb.:</h2>
        <h3 style={{ textDecoration: "underline" }}>Minimální požadavky §3 odst.1 NV 378/2001 Sb.:</h3>

        {subTasks.map((subTask, i) => (
          <div key={subTask.uuid}>
            <Divider>
              <Badge className={"mr-1"}
                value={getSubTaskBadgeValue(subTask)}
                severity={getRevisionSubTaskStateSeverity(subTask)} />
              <label><i className={"my-2"}>{subTask.description}</i></label>
            </Divider>

            {subTask.sub_description && (
              <div className="mb-3"><i>{subTask.sub_description}</i></div>
            )}

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>
                {subTask.value_description} ({subTask.value_unit}{subTask.value_min ? `, min: ${subTask.value_min}` : ""}{subTask.value_max ? `, max: ${subTask.value_max}` : ""})
              </label>
              <div className={"p-inputgroup"}>
                <Dropdown placeholder={subTask.value_description}
                  options={verificationMethods}
                  value={subTask.value || ""}
                  disabled={subTask.state !== "UNKNOWN"}
                  onChange={(e) => handleSubTaskChange(subTask, "value", e.value)}
                />

                {/* 
                  <InputText value={subTask.state_description || ""}
                  placeholder={"Poznámka"}
                  disabled={subTask.state !== "UNKNOWN"}
                  onChange={(e) => handleSubTaskChange(subTask, "state_description", e.target.value)} /> 
                  */}

                {subTask.state === "UNKNOWN" && (
                  <>
                    {/* <Tooltip target=".t1" /> */}
                    <span className="t1 flex" data-pr-tooltip="Úspěšně splněno." data-pr-position="top">
                      <Button type={"button"}
                        label="ANO"
                        className={"p-button-success"}
                        onClick={() => updateSubtask(subTask, "FINISHED")}
                        disabled={isLoading || !subTask.value}
                        style={{ borderRadius: 0 }}
                      />
                    </span>

                    {/* <Tooltip target=".t2" /> */}
                    <span className="t2 flex" data-pr-tooltip="Úspěšně splněno s nalezenou závadou. Vytváří se hlášení." data-pr-position="top">
                      <Button type={"button"}
                        label="částečně"
                        className={"p-button-warning"}
                        onClick={() => openSubTask(subTask, "PARTIALY_FINISHED")}
                        disabled={isLoading || !subTask.value}
                        style={{ borderRadius: 0 }}
                      />
                    </span>

                    {/* <Tooltip target=".t3" /> */}
                    <span className="t3 flex" data-pr-tooltip="Úkol nelze dokončit. Volí se důvod nedokončení." data-pr-position="top">
                      <Button type={"button"}
                        label="NE"
                        className={"p-button-danger"}
                        onClick={() => openSubTask(subTask, "NOT_FINISHED")}
                        disabled={isLoading || !subTask.value}
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      />
                    </span>
                  </>
                )}

                {task.state === "IN_PROGRESS"
                  && subTask.state !== "UNKNOWN"
                  && (
                    <Button type={"button"} title={"Znovu otevřít podúkol"}
                      className={"p-button-primary"} icon={"pi pi-refresh"}
                      onClick={() => reopenSubtask(subTask)} />
                  )}
              </div>

              <div className={"field mt-3"}>
                {(subTask.files.length > 0 || subTask.state === "UNKNOWN") && (
                  <label>Přílohy k dokončení podúkolu:</label>
                )}

                <FileUpload files={subTask.files}
                  setFiles={(files) => setSubTaskFiles(subTask, files)}
                  disabled={subTask.state !== "UNKNOWN"}
                  showClear={subTask.state === "UNKNOWN"}
                  originalFileUuids={originalFileUuids} />
              </div>

              {subTask.uuid === subTaskOpen.uuid && (
                <>
                  <Divider>Hlášení</Divider>

                  <div className={"field"}>
                    <label className={commonStyles.labelRequired}>Název:</label>
                    <InputText value={subTaskOpenReportName} placeholder={"Název"}
                      onChange={handleSubTaskOpenReportNameChange} maxLength={30} />
                  </div>
                  <div className={"field"}>
                    <label className={commonStyles.labelRequired}>Popis:</label>
                    <InputText value={subTaskOpenReportDesc} placeholder={"Popis"}
                      onChange={handleSubTaskOpenReportDescChange} />
                  </div>
                  <div className={"field"}>
                    <FileUpload files={subTaskOpenReportFiles}
                      setFiles={setSubTaskOpenReportFiles}
                      showClear={true} />
                  </div>

                  <Button type={"button"}
                    className={subTaskOpenState === "NOT_FINISHED" ? "p-button-danger" : "p-button-warning"}
                    label={"Dokončit a vytvořit hlášení"}
                    disabled={!subTaskOpenReportName || !subTaskOpenReportDesc}
                    onClick={() => createReport(subTask)} />
                </>
              )}
            </div>
            {renderRelatedSubTaskDetail(subTask.uuid)}
          </div>
        ))}

        <Divider />

        <div className="field-checkbox">
          <Checkbox checked={supportDoc} onChange={onSupportDocChange} inputId="support-doc" disabled={isPreventionDisabled} />
          <label htmlFor="support-doc">K zařízení existuje <strong>průvodní dokumentace</strong></label>
        </div>

        <div className="field-checkbox">
          <Checkbox checked={operationalDoc} onChange={onOperationalDocChange} inputId="operational-doc" disabled={isPreventionDisabled} />
          <label htmlFor="operational-doc">K zařízení existuje <strong>provozní dokumentace</strong></label>
        </div>

        <div className="field">
          <label className={styles.label}>Závady</label>
          <InputTextarea value={defects} onChange={onDefectsChange} disabled={isPreventionDisabled} />
        </div>

        <div className="field-checkbox">
          <Checkbox checked={fulfill} onChange={onFulfillChange} inputId="fulfill"
            disabled={isPreventionDisabled || someSubtaskIsNotFinished} />
          <label htmlFor="fulfill"><strong>Vizuálně, zkouškou a měřením bylo ověřeno, že výše uvedené
            kontrolované zařízení požadavky §3 odst. 1 NV č. 378/2001 Sb. SPLŇUJE.</strong></label>
        </div>

        <div className="field">
          Tyto požadavky: a), b), c), d), e), f), g), h), i), j), k), l), m), n), o), p), q), r), s), t) ověřil:
        </div>

        <div className="field">
          <label className={commonStyles.labelRequired}>Jméno</label>
          <InputText value={firstname} onChange={onFirstnameChange} disabled={isPreventionDisabled} />
        </div>

        <div className="field">
          <label className={commonStyles.labelRequired}>Příjmení</label>
          <InputText value={lastname} onChange={onLastnameChange} disabled={isPreventionDisabled} />
        </div>

        <div className="field">
          <label className={commonStyles.labelRequired}>Funkce</label>
          <InputText value={job} onChange={onJobChange} disabled={isPreventionDisabled} />
        </div>

        <div className="field">
          <label className={commonStyles.labelRequired}>Datum</label>
          <Calendar value={date} onChange={onDateChange} dateFormat="d. m. yy" disabled={isPreventionDisabled} />
        </div>
      </div>

      <div className={"flex"}>
        <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
          onClick={back} />

        {(hasRole("ADMIN") || hasRole("SUPERADMIN") || hasRole("SUPERVISOR")) && (
          <Button type={"button"} label={"Přehled"} className={"p-button-warning m-1"}
            onClick={backToForm} />
        )}

        {showExport && (
          <Button type="button" label="Exportovat do PDF" onClick={onExport} className="m-1" />
        )}

        {!hasRole("USER") &&
          task.state === "IN_PROGRESS" &&
          !subTasks.some((subTask) => subTask.state === "UNKNOWN") && (
            <Button type={"button"} className={"p-button-primary m-1"}
              onClick={finishPrevention} label={"Dokončit údržbu"}
              disabled={!firstname || !lastname || !job}
            />
          )}
      </div>
    </div>
  )
}
