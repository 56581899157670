import { useState, useEffect } from "react"
import { useImmer } from "use-immer"
import { useHistory, useLocation } from "react-router-dom"
import { Dropdown } from "primereact/dropdown"
import { DataTable } from "primereact/datatable"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { Column } from "primereact/column"
import FileThumb from "../../../File/FileThumb"
import FileUpload from "../../../File/FileUpload"
import { useMachineTypes } from "../../../../api/queries/useMachineTypes"
import api from "../../../../api"
import commonStyles from "../../../common.module.css"
import query from "../../../../function/query"
import { useMachines } from "../../../../api/queries/useMachines"

export default function TaskPreventionBulkSubtaskForm() {
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)

  const { data: machineTypes = [] } = useMachineTypes()
  const [machineType, setMachineType] = useState(search.machine_type || null)
  const handleMachineTypeChange = (e) => {
    setMachineType(e.target.value)
    setMachine(null)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [tasks, setTasks] = useState([])

  const [selectedRows, setSelectedRows] = useState([])
  const handleSelectionChange = (e) => setSelectedRows(e.value)

  const [editedSubTaskPosition, setEditedSubTaskPosition] = useState(null)

  const [subTaskDescription, setSubTaskDescription] = useState("")
  const handleSubTaskDescriptionChange = (e) => setSubTaskDescription(e.target.value)
  
  const [subTaskSubDescription, setSubTaskSubDescription] = useState("")
  const handleSubTaskSubDescriptionChange = (e) => setSubTaskSubDescription(e.target.value)

  const [subTaskValueDescription, setSubTaskValueDescription] = useState("")
  const handleSubTaskValueDescriptionChange = (e) => setSubTaskValueDescription(e.target.value)

  const [subTaskValueUnit, setSubTaskValueUnit] = useState("")
  const handleSubTaskValueUnitChange = (e) => setSubTaskValueUnit(e.target.value)

  const [subTaskFiles, setSubTaskFiles] = useState([])

  const [subTasks, setSubTasks] = useImmer([])

  const tLoadSubTasks = (row, { selected }) => (
    <Button label={"Načíst podúkoly"} disabled={!selected}
      className={"p-button-sm p-button-link"}
      onClick={() => loadSubTasks(row.uuid)} />
  )

  const { data: allMmachines = [] } = useMachines()
  const tMachine = (row) => row.machine ? row.machine.name : ""
  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machines = machineType
    ? allMmachines.filter((m) => m.type.uuid === machineType)
    : allMmachines
  const machineFilter = (
    <Dropdown options={machines} value={machine} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Stroj"} onChange={handleMachineChange} filter={true} />
  )

  const addSubTask = () => {
    setSubTasks((draft) => {
      draft.push({
        description: subTaskDescription,
        sub_description: subTaskDescription,
        value_description: subTaskValueDescription,
        value_unit: subTaskValueUnit,
        position: subTasks.length + 1,
        files: subTaskFiles,
      })
    })

    setSubTaskDescription("")
    setSubTaskSubDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskFiles([])
  }

  const moveSubTaskUp = (index) => {
    if (index > 1) {
      setSubTasks((draft) => {
        let prev = draft.find((subTask) => subTask.position === index - 1)
        let curr = draft.find((subTask) => subTask.position === index)

        prev.position = index
        curr.position = index - 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const moveSubTaskDown = (index) => {
    if (index < subTasks.length) {
      setSubTasks((draft) => {
        let next = draft.find((subTask) => subTask.position === index + 1)
        let curr = draft.find((subTask) => subTask.position === index)

        next.position = index
        curr.position = index + 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const removeSubTask = (index) => {
    setSubTasks(() => {
      let newDraft = []

      subTasks.forEach((subTask) => {
        if (subTask.position !== index)
          newDraft.push(Object.assign({}, subTask))
      })

      newDraft.forEach((subTask, i) => {
        subTask.position = i + 1
      })

      return newDraft
    })
  }

  const handleEditSubTaskClick = (index) => {
    setEditedSubTaskPosition(index)

    const subTask = subTasks.find((subTask) => subTask.position === index)

    setSubTaskDescription(subTask.description || "")
    setSubTaskSubDescription(subTask.sub_description || "")
    setSubTaskValueDescription(subTask.value_description || "")
    setSubTaskValueUnit(subTask.value_unit || "")
    setSubTaskFiles(subTask.files || [])
  }

  const editSubTask = () => {
    setSubTasks((draft) => {
      const subTask = draft.find((subTask) => subTask.position === editedSubTaskPosition)

      subTask.description = subTaskDescription
      subTask.sub_description = subTaskSubDescription
      subTask.value_description = subTaskValueDescription
      subTask.value_unit = subTaskValueUnit
      subTask.files = subTaskFiles
    })

    // reset
    setSubTaskDescription("")
    setSubTaskSubDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskFiles([])
    setEditedSubTaskPosition(null)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    const updateTask = async (task) => {
      await api.patch(`/tasks/${task.uuid}/close`, {})

      const payload = {
        type: "PREVENTION",
        operation: task.operation.uuid,
        name: task.name,
        description: task.description,
        repeated: task.repeated,
        team: task.team.uuid,
        machine: task.machine.uuid,
        starts_at: task.starts_at,
        order: task.order || "",
        tasks: subTasks,
      }

      if (task.repeated_value) {
        payload.repeated_value = task.repeated_value
      }

      if (task.files) {
        payload.files = task.files
      }

      await api.put("/tasks", payload)
    }

    for (let i = 0; i < selectedRows.length; i++) {
      await updateTask(selectedRows[i])
    }

    alert("Podúkoly byly aktualizovány")

    history.push("/tasks/preventions")
  }

  useEffect(() => {
    if (machineType) {
      setIsLoading(true)

      let payload = {
        type: "PREVENTION",
        view: "active",
        machine_type: machineType,
        machine,
      }

      const payloadStr = query.toString(payload)
      history.replace({ search: payloadStr })

      api.get(`/tasks${payloadStr}`)
        .then(({ data }) => setTasks(data.data))
        .finally(() => setIsLoading(false))
    }
  }, [machineType, machine, history])

  const loadSubTasks = async (taskUuid) => {
    const { data } = await api.get(`/tasks/${taskUuid}`)
    const tasks = data.data.tasks

    setSubTasks((draft) => {
      draft = draft.filter(() => false)
      tasks.forEach((task, i) => {
        draft.push({
          description: task.description,
          sub_description: task.sub_description || "",
          value_description: task.value_description || "",
          value_unit: task.value_unit || "",
          position: task.position || i,
          files: task.files,
        })
      })

      return draft
    })
  }

  return (
    <>
      <div className={"col-12 md:col-8"}>
        <div className={"p-fluid field mb-0"}>
          <h3>Typ stroje:</h3>
          <Dropdown required={true} value={machineType} options={machineTypes}
            optionValue={"uuid"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
            onChange={handleMachineTypeChange} />
        </div>
      </div>

      <div className={"col-12"} />

      <div className={"col-12 md:col-8"}>
        <h3>Pravidelná údržba</h3>

        <DataTable emptyMessage={"Žádné záznamy"} value={tasks} loading={isLoading}
          className={"p-datatable-sm"}
          selectionMode={"checkbox"} selection={selectedRows} onSelectionChange={handleSelectionChange}>
          <Column selectionMode={"multiple"} headerStyle={{ width: '3em' }} />
          <Column header={"#"} field={"id"} />
          <Column header={"Název"} field={"name"} />
          <Column header={"Popis"} field={"description"} />
          <Column header={"Stroj"} field={"machine"} body={tMachine}
            filter={true} filterElement={machineFilter} />
          <Column header={""} body={tLoadSubTasks} />
        </DataTable>
      </div>

      <div className={"col-12"} />

      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <h3>Podúkoly</h3>
        <div className={"p-fluid"}>
          {subTasks.length === 0 && <p>Žádné podúkoly.</p>}

          <ul className={"pl-4"}>
            {subTasks.map((subTask) => (
              <li key={subTask.description}>
                <div className={"flex align-items-center"}>
                  {subTask.description}

                  {subTask.value_description && (
                    <small className={"text-sm ml-1"}>
                      ({subTask.value_description}/{subTask.value_unit})
                    </small>
                  )}

                  <div className={"py-1 ml-auto"}>
                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-arrow-up"}
                      tooltip={"Posunout o pozici výše"}
                      tooltipOptions={{
                        position: "left"
                      }}
                      type={"button"}
                      onClick={() => moveSubTaskUp(subTask.position)} />

                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-arrow-down"}
                      tooltip={"Posunout o pozici níže"}
                      tooltipOptions={{
                        position: "left"
                      }}
                      type={"button"}
                      onClick={() => moveSubTaskDown(subTask.position)} />

                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-pencil"}
                      tooltip={"Upravit"}
                      tooltipOptions={{
                        position: "left"
                      }}
                      type={"button"}
                      onClick={() => handleEditSubTaskClick(subTask.position)} />

                    <Button className={"p-button-text p-1 p-button-danger"}
                      icon={"pi pi-times"}
                      tooltip={"Smazat"}
                      tooltipOptions={{
                        position: "left"
                      }}
                      type={"button"}
                      onClick={() => removeSubTask(subTask.position)} />
                  </div>
                </div>

                {subTask.sub_description && (
                  <div className="mb-3"><i>{subTask.sub_description}</i></div>
                )}

                <div className={"flex flex-wrap"}>
                  {subTask.files && subTask.files
                    .filter((file) => file.createdBy !== "USER")
                    .map((file) => (
                      <FileThumb key={file.uuid} file={file} isLarge={false} />
                    ))}
                </div>
              </li>
            ))}
          </ul>

          <Divider className={"font-normal"}>
            {editedSubTaskPosition ? "Aktualizovat" : "Nový"} podúkol:
          </Divider>

          <div className={"field"}>
            <label>Název:</label>
            <InputText value={subTaskDescription} onChange={handleSubTaskDescriptionChange}
              placeholder={"Název"} />
          </div>

          <div className={"field"}>
            <label>Popis:</label>
            <InputText value={subTaskSubDescription} onChange={handleSubTaskSubDescriptionChange}
              placeholder={"Popis"} />
          </div>

          <div className={"field"}>
            <label>Sledovaná hodnota:</label>
            <div className={"p-inputgroup"}>
              <InputText value={subTaskValueDescription}
                onChange={handleSubTaskValueDescriptionChange} placeholder={"Název"}
                disabled={editedSubTaskPosition && !subTaskValueDescription} />
              <InputText value={subTaskValueUnit} onChange={handleSubTaskValueUnitChange}
                placeholder={"Jednotka"}
                disabled={editedSubTaskPosition && !subTaskValueUnit} />
            </div>
            <small className={commonStyles.fieldHelp}>Zadejte pouze v případě, že chcete aby
              pracovník vyplňoval proměnnou veličinu (např. velikost nákolku kola
              jeřábu).</small>
          </div>

          <div className={"field"}>
            <FileUpload files={subTaskFiles} setFiles={setSubTaskFiles} showClear={true} />
          </div>
        </div>

        <Button className={"p-button-raised p-button-text ml-1 mb-3"}
          type={"button"}
          disabled={!subTaskDescription}
          label={editedSubTaskPosition ? "Aktualizovat podúkol" : "Přidat podúkol"}
          onClick={editedSubTaskPosition ? editSubTask : addSubTask} />

        <div className={"pt-5"}>
          <Button label={"Uložit a aktualizovat podúkoly vybraných údržeb"}
            className={"p-button-success"} icon={"pi pi-save"}
            disabled={isLoading || selectedRows.length === 0} />
        </div>
      </form>
    </>
  )
}
