export const APP_VERSION = "v20250211"

const AddedIcon = () => <i className={"fas fa-plus mr-1"} title={"Přidáno"} />
const FixedIcon = () => <i className={"fas fa-bug-slash mr-1"} title={"Opraveno"} />
const ChangedIcon = () => <i className={"fas fa-repeat mr-1"} title={"Změněno"} />
const List = ({ children }) => <ul className={"pl-3 list-none"}>{children}</ul>
const ListItem = ({ children }) => <li className={"my-1"}>{children}</li>

export default function Changelog() {
    return (
        <div className={"col-12"}>
            <h2>11. 2. 2025</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    přidána poznámka k podúkolu pravidelné údržby
                </ListItem>
            </List>
            <h2>7. 11. 2024</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    pravidelná roční údržba dle NV č. 378
                </ListItem>
            </List>
            <h2>29. 7. 2024</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    přidán VZV deník
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidán sloupec datum dokončení do přehledu hlášení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidány vysvětlivky k tlačítkům pro dokončení podúkolů pravidelné údržby
                </ListItem>
            </List>
            <h2>12. 7. 2024</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveny některé hodnoty v GFP reportu
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno generování údržeb v lokálním čase
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost vytvoření mimořádného GFP
                </ListItem>
            </List>
            <h2>29. 4. 2024</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraven přepínač "zobrazit smazané" v seznamu pracovníků
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    upraveno mazání příloh u podúkolů pravidelné údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    nahravání příloh nyní umožňuje hromadný výběr
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost nového generování pravidelné údržby - pracovní dny
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    GWP nyní umožňuje nahrát přílohy
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    upraven formulář hlášení typu nekvalita
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    generování GFP nyní bere v potaz obsazení směn
                </ListItem>
            </List>
            <h2>22. 1. 2024</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveno nahrávání přílohy k podúkolu existující pravidelné údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidán proklik na detail GWP z GFP
                </ListItem>
            </List>
            <h2>9. 1. 2024</h2>
            <List>
                <ListItem>
                    <ChangedIcon />
                    upravena pozice a chovaní pole zakázka při vytváření nového hlášení
                </ListItem>
            </List>
            <h2>4. 12. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opravena chyba, kdy nedošlo k uložení zakázky při editaci hlášení
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opravena chyba "Token not found"
                </ListItem>
            </List>
            <h2>9. 11. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveno odhlašování uživatele, které za určitých podmínek mohlo skončit chybou
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost vytvořit hlášení z managementu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost vytvářet související podúkoly k hlášení
                </ListItem>
            </List>
            <h2>2. 11. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    u existující pravidelné údržby lze doplnit limity sledovaných hodnot
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    detail pravidelné údržby obsahuje hodnotu opakování
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    upravena velikost pole popis v pravidelné údržbě
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    přehledy na mobilních zařízeních zobrazují filtry na první pozici
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    upraveno zobrazení unikátní pravidelné údržby v detailu stroje
                </ListItem>
            </List>
            <h2>5. 10. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveno načítání míst v nastavení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pravidelnou údržbu lze nově přiřadit provozu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pravidelnou údržbu lze nově přiřadit odpovědné osobě
                </ListItem>
            </List>
            <h2>25. 9. 2023</h2>
            <List>
                <ListItem>
                    <ChangedIcon />
                    upraveno chování tlačítka pro odeslání hromadného e-mailu pracovníkům
                </ListItem>
            </List>
            <h2>28. 8. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraven odkaz na detail mimořádné údržby
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    pro zobrazení příloh je nutné být přihlášen v aplikaci
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    uživatel má nově možnost změnit si sám heslo
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost odeslání hromadného e-mailu pracovníkům
                </ListItem>
            </List>
            <h2>14. 8. 2023</h2>
            <List>
                <ListItem>
                    <ChangedIcon />
                    upraveno zobrazování strojů v aplikaci. nově se zobrazuje i typ stroje, místo a název nadřazeného stroje
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    povoleno mazání příloh u podúkolů pravidelné údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    podúkoly u pravidelné údržby nově umožňují u sledovaných hodnot zadat i limity
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    název stroje v hlášení nově vede na detail stoje
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána mimořádná údržba
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    nově lze ze seznamu uživatelů poslat hromadný email
                </ListItem>
            </List>
            <h2>20. 7. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveno nahrávání příloh ke strojům
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    nově vytvořené stroje je možné použít bez obnovení stránky
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    pracovník může nově znovuotevřít podúkol pravidelné údržby
                </ListItem>
                <ListItem></ListItem>
            </List>
            <h2>22. 6. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opravena duplicitní notifikace při dokončení hlášení
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno nahrávání příloh u existujích úkolů
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno zobrazování stroje údržby pro uživatele z jiného provozu
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    upraven seznam důvodů neprovedení údržby, přidán popis
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    hlášení lze nově vytvořit kýmkoliv pro jakýkoliv provoz
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    diskuse v managementu nyní umožňuje označit uživatele
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána legenda barev
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidáno fulltextové hledání úkolů
                </ListItem>
            </List>
            <h2>9. 5. 2023</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    mezi úkoly hlášení a managementu lze nyní procházet přímo z detailu úkolu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pravidelná údržba nově zobrazuje varovaní pokud předchozí údržba nebyla řádně dokončena
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    notifikace nově zobrazují pouze poslední notifikaci k danému úkolu jako nepřečtenou
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno vytváření nové pravidelné údržby pokud obsahuje přílohy
                </ListItem>
            </List>
            <h2>27. 4. 2023</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    detail hlášení nově obsahuje informaci o typu hlášení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    detail stroje nově obsahuje nepovinné pole poznámka
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    e-mailová notifikace nově obsahuje odkaz na detail hlášení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    typy úkolů lze nově řadit
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    upraveno zobrazeni GFP na mobilu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pracovníci a stroje se nově nemažou, pouze deaktivují
                </ListItem>
            </List>
            <h2>4. 4. 2023</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    vytváření nového stroje zobrazuje defaultně místa z provozu přihlášeného uživatele
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    do nastavení míst přidán filtr na provoz
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraven čas vytváření úkolů
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    seznam údržby v detailu stroje zobrazuje pouze aktivní údržbu
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    upraveny popisky tlačítek v detailu údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidáno GWP
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidáno GFP
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    sklady jsou nyni defaultně filtrovány dle provozu
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno barevné označení hotových úkolů
                </ListItem>
            </List>
            <h2>13. 2. 2023</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opraveno nahrávání souborů v managementu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    upraveny časy zahájení a dokončení (pravidelná údržba 06:00-*, hlášení 00:00-23:59)
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    detail stroje obsahuje informace o údržbách na tomto stroji
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pravidelná údržba zobrazuje informace o aktuálních nedokončených hlášeních
                </ListItem>
            </List>
            <h2>19. 12. 2022</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opravena funkce připomenout v sekci management
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    upraven výchozí koncový čas pravidelné údržby tak, aby jednodenní údržba končila ve stejný den
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    pravidelnou údržbu nyní nelze založit bez podúkolu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    podúkoly pravidelné údržby nově zobrazují název stroje
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    ukončení pravidelné údržby nově přesune údržbu do historie
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    uložení existující pravidelné údržby nově přesměruje na novou údržbu, namísto té původní
                </ListItem>
            </List>
            <h2>14. 11. 2022</h2>
            <List>
                <ListItem>
                    <ChangedIcon />
                    změna stavu hlášení "probíhá" nastane až po kliknutí na tlačítko převzít
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    do hromadné akce - změna podúkolů byl přidán sloupec Stroj
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    při vytváření hlášení a pravidelné údržby je provoz filtrován dle přihlášeného uživatele
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    při vytváření nového hlášení lze nově stroj filtrovat dle provozu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    při vytváření nové údržby jsou stroje a týmy filtrovány dle provozu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    při vytváření nového hlášení jsou odpovědné osoby filtrovány dle týmu a provozu
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána nápověda pro tlačítka uzavřít a uzavřít novou v detailu pravidelné údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána nápověda pro tlačítka uložit a uložit nové v detailu hlášení
                </ListItem>
            </List>
            <h2>14. 10. 2022</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    stav hlášení v přehledu pravidelné údržby nyní správně počítá dokončená hlášení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    upraveno chování keše, které by mělo vést ke zrychlení celé aplikace
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    u všech přehledů je možné nastavit počet záznámů na stránku (20, 50, 100)
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    při dokončení hlášení se nyní nabízí zadavatel hlášení jako odpovědná osoba
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidány filtry v přehledech hromadných úprav
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    v diskusi hlášení lze nyní použít @mentions pro notifikování vybraného uživatele
                </ListItem>
            </List>
            <h2>8. 9. 2022</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    přidána možnost hromadné změny podúkolů pravidelné údržby
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidána možnost hromadné změny stavu podúkolů pravidelné údržby
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    sloupec pravidelné údržby "Dokončeno podúkolů" nově počítá mezi dokončené podúkoly pouze ty, které jsou ve stavu "Dokončeno" nebo "Dokončeno, nalezena závada"
                </ListItem>
            </List>

            <h2>24. 8. 2022</h2>
            <List>
                <ListItem>
                    <FixedIcon />
                    opravena chyba pri dokončení pravidelné údržby
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveny odkazy ve strojích
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidány filtry provoz, tým a role do přehledu pracovníků
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    barevné označení stavů podúkolů pravidelné údržby bylo sjednoceno s barvami tlačítek
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    SMS notifikace nyní obsahuje i název stroje
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    řádky v přehledu pravidelné údržby se nyní nepodbarvují dle typu
                </ListItem>
            </List>
            <h2>27. 7. 2022</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    pravidelnou údržbu lze nyní dokončit pomocí "dokončeno, nalezena závada"
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    k podúkolům pravidelné údržby lze přidat přílohy
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    k pracovníkům lze volitelně přiřadit stroj/stroje
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    k hlášením vytvářeným z pravidelné údržby lze přidat přílohy
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    upraveno mazání uživatelů, přidán filtr na smazané uživatele
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    v přehledu pravidelné údržby se zobrazuje statistika dokončených hlášení
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    nedokončení podúkolu pravidelné údržby nevytváří hlášení
                </ListItem>
            </List>
            <h2>19. 7. 2022</h2>
            <List>
                <ListItem>
                    <ChangedIcon />
                    odstraněn typ úkolu u pravidelné údržby
                </ListItem>
                <ListItem>
                    <ChangedIcon />
                    odkaz z přehledu pravidelné údržby nyní vede na seznam podúkolů
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    hlášení s typem TPM jsou nyní podbarveny šedivou barvou
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pole zakázka v úkolu se nyní rozbaluje
                </ListItem>
            </List>
            <h2>7. 6. 2022</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    přehled změn v aplikaci
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přehled pravidelné údržby zobrazuje informace o počtu dokončených podúkolů
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    přidán sloupec "Datum založení" do přehledu hlášení
                </ListItem>
                <ListItem>
                    <AddedIcon />
                    pravidelnou údržbu je nyní nutné dokončit manuálně
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    zkopírování pravidelné údržby nyní nepřenáší soubory
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    opraveno filtrování přehledů na mobilu
                </ListItem>
                <ListItem>
                    <FixedIcon />
                    pracovníci nevidí tlačítko pro vytvoření nové pravidelné údržby
                </ListItem>
            </List>

            <h2>28. 3. 2022</h2>
            <List>
                <ListItem>
                    <AddedIcon />
                    spuštěna produkční verze aplikace Dora
                </ListItem>
            </List>
        </div>
    )
}
