import { useContext, useEffect, useRef, useState } from "react"
import { NavLink, useParams, Redirect } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { InputTextarea } from "primereact/inputtextarea"
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { Divider } from "primereact/divider"
import { Accordion, AccordionTab } from "primereact/accordion"
import moment from "moment"
import { useImmer } from "use-immer"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useMachines } from "../../../api/queries/useMachines"
import { useTeams } from "../../../api/queries/useTeams"
import { useTaskRepeated } from "../../../api/queries/useTaskRepeated"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { useTaskStates } from "../../../api/queries/useTaskStates"
import FileList from "../../File/FileList"
import WarehouseItemForm from "../WarehouseItemForm"
import TaskHistory from "../TaskHistory"
import FileUpload from "../../File/FileUpload"
import FileThumb from "../../File/FileThumb"
import { Fieldset } from "primereact/fieldset"
import { useOperations } from "../../../api/queries/useOperations"
import { usePlaces } from "../../../api/queries/usePlaces"
import { useMachineTypes } from "../../../api/queries/useMachineTypes"
import { useHistory } from "react-router-dom"
import { NotFinishedInfo } from "./NotFinishedInfo"
import { formatMachineName } from "../../../function/machine"
import useParentUsers from "../../../hook/useParentUsers"
import { minimalRequirementsSubtaks } from "./minimal-requirements-subtaks"

export default function TaskPreventionForm() {
  const usehistory = useHistory()
  const { uuid } = useParams()
  const { data: teams = [] } = useTeams()
  const back = () => window.history.back()
  const { user: userDetail, hasPerm, hasRole } = useContext(userContext)
  const refBtnSave = useRef(null)

  const [task, setTask] = useState(null)

  const [state, setState] = useState("OPEN")
  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)
  const { data: repeatedOptions = [] } = useTaskRepeated()
  const [repeated, setRepeated] = useState("")
  const [repeatedValue, setRepeatedValue] = useState(1)
  const handleRepeatedValueChange = (e) => {
    const value = parseInt(e.target.value, 10)
    if (!isNaN(value)) {
      setRepeatedValue(value)
    }
  }
  const handleRepeatedChange = (e) => setRepeated(e.target.value)

  const dayOptions = [
    { label: "Pondělí", value: 1 },
    { label: "Úterý", value: 2 },
    { label: "Středa", value: 3 },
    { label: "Čtvrtek", value: 4 },
    { label: "Pátek", value: 5 },
    { label: "Sobota", value: 6 },
    { label: "Neděle", value: 7 },
  ]
  const [days, setDays] = useState([])
  const onDaysChange = (e) => setDays(e.value)
  const daysToNumber = () => {
    let bin = ""

    for (let i = 1; i <= 7; i++) {
      bin += days.indexOf(i) > -1 ? "1" : "0"
    }

    return parseInt(bin, 2)
  }
  const numberToDays = (num) => {
    const bin = num.toString(2).padStart(7, "0")
    let arr = []

    for (let i = 0; i < 7; i++) {
      if (bin.at(i) === "1") {
        arr.push(i + 1)
      }
    }

    setDays(arr)
  }

  const [startsAt, setStartsAt] = useState(moment().set({ h: 6, m: 0 })._d)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)

  const [operation, setOperation] = useState(userDetail.operation ? userDetail.operation.uuid : "")
  const onOperationChange = (e) => {
    setOperation(e.value)
    setResponsibleUser(null)
    setTeam(null)
  }

  const users = useParentUsers(userDetail, operation)
  const [responsibleUser, setResponsibleUser] = useState(null)
  const handleResponsibleUserChange = (e) => setResponsibleUser(e.target.value)
  const userTemplate = (user) => <div>{user.name} ({user.operation.name}, {user.team.name})</div>
  const getUserOptions = () => team
    ? users.filter((user) => user.team.uuid === team)
    : users

  const [team, setTeam] = useState("")
  const handleTeamChange = (e) => {
    setTeam(e.target.value)
    setResponsibleUser(null)
  }
  const getTeamOptions = () => {
    // users are already filtered by operations
    const _teams = users.map((user) => user.team.uuid)
    return teams.filter((team) => _teams.indexOf(team.uuid) > -1)
  }

  const [order, setOrder] = useState("")
  const handleOrderChange = (e) => setOrder(e.target.value)

  // machine
  const { data: machines = [] } = useMachines()
  const { data: operations = [] } = useOperations()
  const { data: places = [] } = usePlaces()
  const { data: machineTypes = [] } = useMachineTypes()

  const [machine, setMachine] = useState(null)
  const handleMachineChange = (e) => setMachine(e.target.value || null)
  const tMachine = (machine) => machine ? formatMachineName(machine) : <span>&nbsp;</span>

  const [MFOperation, setMFOperation] = useState(userDetail.operation ? userDetail.operation.uuid : null)
  const handleMFOperationChange = (e) => {
    setMFOperation(e.target.value)
    setMachine(null)
    setMFPlace(null)
    setMFType(null)
  }
  const getMFOperationOptions = () => operations // operation is not filtered

  const [MFPlace, setMFPlace] = useState(null)
  const handleMFPlaceChange = (e) => {
    setMFPlace(e.target.value)
    setMachine(null)
    setMFType(null)
  }
  const getMFPlaceOptions = () => MFOperation
    ? places.filter((place) => place.operation.uuid === MFOperation)
    : places

  const [MFType, setMFType] = useState(null)
  const handleMFTypeChange = (e) => {
    setMFType(e.target.value)
    setMachine(null)
  }
  const getMFTypeOptions = () => {
    const _places = getMFPlaceOptions().map((place) => place.uuid)
    const _machines = MFPlace
      ? machines.filter((machine) => machine.place.uuid === MFPlace)
      : machines.filter((machine) => _places.indexOf(machine.place.uuid) > -1)
    const _types = _machines.map((machine) => machine.type.uuid)
    return machineTypes.filter((machineFilter) => _types.indexOf(machineFilter.uuid) > -1)
  }

  const getMachineOptions = () => {
    // if (task && task.machine) {
    //   return [...machines]
    // }

    let _machines = [...machines]

    if (MFOperation) {
      _machines = _machines.filter((machine) => machine.place.operation.uuid === MFOperation)
    }

    if (MFPlace) {
      _machines = _machines.filter((machine) => machine.place.uuid === MFPlace)
    }

    if (MFType) {
      _machines = _machines.filter((machine) => machine.type.uuid === MFType)
    }

    return _machines
  }

  // sub tasks
  const [someSubTaskWithoutPosition, setSomeSubTaskWithoutPosition] = useState(false)
  const [editedSubTaskPosition, setEditedSubTaskPosition] = useState(null)

  const [subTaskDescription, setSubTaskDescription] = useState("")
  const handleSubTaskDescriptionChange = (e) => setSubTaskDescription(e.target.value)
  
  const [subTaskSubDescription, setSubTaskSubDescription] = useState("")
  const handleSubTaskSubDescriptionChange = (e) => setSubTaskSubDescription(e.target.value)

  const [subTaskValueDescription, setSubTaskValueDescription] = useState("")
  const handleSubTaskValueDescriptionChange = (e) => setSubTaskValueDescription(e.target.value)

  const [subTaskValueUnit, setSubTaskValueUnit] = useState("")
  const handleSubTaskValueUnitChange = (e) => setSubTaskValueUnit(e.target.value)

  const [subTaskValueMin, setSubTaskValueMin] = useState(null)
  const handleSubTaskValueMinChange = (e) => setSubTaskValueMin(e.value)

  const [subTaskValueMax, setSubTaskValueMax] = useState(null)
  const handleSubTaskValueMaxChange = (e) => setSubTaskValueMax(e.value)

  const [subTaskFiles, setSubTaskFiles] = useState([])

  const [subTasks, setSubTasks] = useImmer([])

  const [subTaskErrorColor, setSubTaskErrorColor] = useState(false)

  const addSubTask = () => {
    setSubTasks((draft) => {
      draft.push({
        description: subTaskDescription,
        sub_description: subTaskSubDescription,
        value_description: subTaskValueDescription,
        value_unit: subTaskValueUnit,
        value_min: subTaskValueMin,
        value_max: subTaskValueMax,
        position: subTasks.length + 1,
        files: subTaskFiles,
      })
    })

    setSubTaskDescription("")
    setSubTaskSubDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskValueMin(null)
    setSubTaskValueMax(null)
    setSubTaskFiles([])
  }

  const moveSubTaskUp = (index) => {
    if (index > 1) {
      setSubTasks((draft) => {
        let prev = draft.find((subTask) => subTask.position === index - 1)
        let curr = draft.find((subTask) => subTask.position === index)

        prev.position = index
        curr.position = index - 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const moveSubTaskUpAsync = async (index) => {
    if (index > 1) {
      const prev = subTasks.find((subTask) => subTask.position === index - 1)
      const curr = subTasks.find((subTask) => subTask.position === index)

      const prevPayload = {
        uuid: prev.uuid,
        description: prev.description,
        sub_description: prev.sub_description,
        value_description: prev.value_description || "",
        value_unit: prev.value_unit || "",
        position: index,
      }

      if (prev.value_min !== null) {
        prevPayload.value_min = prev.value_min
      }

      if (prev.value_max !== null) {
        prevPayload.value_max = prev.value_max
      }

      const currPayload = {
        uuid: curr.uuid,
        description: curr.description,
        sub_description: curr.sub_description,
        value_description: curr.value_description || "",
        value_unit: curr.value_unit || "",
        position: index - 1,
      }

      if (curr.value_min !== null) {
        currPayload.value_min = curr.value_min
      }

      if (curr.value_max !== null) {
        currPayload.value_max = curr.value_max
      }

      await api.post(`/tasks/${uuid}/sub/${prev.uuid}`, prevPayload)
      await api.post(`/tasks/${uuid}/sub/${curr.uuid}`, currPayload)

      refresh()
    }
  }

  const moveSubTaskDown = (index) => {
    if (index < subTasks.length) {
      setSubTasks((draft) => {
        let next = draft.find((subTask) => subTask.position === index + 1)
        let curr = draft.find((subTask) => subTask.position === index)

        next.position = index
        curr.position = index + 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const moveSubTaskDownAsync = async (index) => {
    if (index < subTasks.length) {
      const next = subTasks.find((subTask) => subTask.position === index + 1)
      const curr = subTasks.find((subTask) => subTask.position === index)

      const nextPayload = {
        uuid: next.uuid,
        description: next.description,
        sub_description: next.sub_description,
        value_description: next.value_description || "",
        value_unit: next.value_unit || "",
        position: index,
      }

      if (next.value_min !== null) {
        nextPayload.value_min = next.value_min
      }

      if (next.value_max !== null) {
        nextPayload.value_max = next.value_max
      }

      const currPayload = {
        uuid: curr.uuid,
        description: curr.description,
        sub_description: curr.sub_description,
        value_description: curr.value_description || "",
        value_unit: curr.value_unit || "",
        position: index + 1,
      }

      if (curr.value_min !== null) {
        currPayload.value_min = curr.value_min
      }

      if (curr.value_max !== null) {
        currPayload.value_max = curr.value_max
      }

      await api.post(`/tasks/${uuid}/sub/${next.uuid}`, nextPayload)
      await api.post(`/tasks/${uuid}/sub/${curr.uuid}`, currPayload)

      refresh()
    }
  }

  const removeSubTask = (index) => {
    setSubTasks(() => {
      let newDraft = []

      subTasks.forEach((subTask) => {
        if (subTask.position !== index)
          newDraft.push(Object.assign({}, subTask))
      })

      newDraft.forEach((subTask, i) => {
        subTask.position = i + 1
      })

      return newDraft
    })
  }

  const handleEditSubTaskClick = (index) => {
    setEditedSubTaskPosition(index)

    const subTask = subTasks.find((subTask) => subTask.position === index)

    setSubTaskDescription(subTask.description || "")
    setSubTaskDescription(subTask.sub_description || "")
    setSubTaskValueDescription(subTask.value_description || "")
    setSubTaskValueUnit(subTask.value_unit || "")
    setSubTaskValueMin(subTask.value_min)
    setSubTaskValueMax(subTask.value_max)
    setSubTaskFiles(subTask.files || [])
  }

  const editSubTask = () => {
    setSubTasks((draft) => {
      const subTask = draft.find((subTask) => subTask.position === editedSubTaskPosition)

      subTask.description = subTaskDescription
      subTask.sub_description = subTaskSubDescription
      subTask.value_description = subTaskValueDescription
      subTask.value_unit = subTaskValueUnit
      subTask.value_min = subTaskValueMin
      subTask.value_max = subTaskValueMax
      subTask.files = subTaskFiles
    })

    // reset
    setSubTaskDescription("")
    setSubTaskSubDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskValueMin(null)
    setSubTaskValueMax(null)
    setSubTaskFiles([])
    setEditedSubTaskPosition(null)
  }

  const editSubTaskAsync = async () => {
    const subTask = subTasks.find((subTask) => subTask.position === editedSubTaskPosition)

    const payload = {
      uuid: subTask.uuid,
      description: subTaskDescription,
      sub_description: subTaskSubDescription,
      value_description: subTaskValueDescription,
      value_unit: subTaskValueUnit,
    }

    if (subTaskValueMin !== null) {
      payload.value_min = subTaskValueMin
    }

    if (subTaskValueMax !== null) {
      payload.value_max = subTaskValueMax
    }

    await api.post(`/tasks/${uuid}/sub/${subTask.uuid}`, payload)

    const addedFiles = subTaskFiles
      .filter((file) => !subTask.files.some((origFile) => file.uuid === origFile.uuid))
      .map((file) => {
        delete file.uuid
        return file
      })

    const deletedFiles = subTask.files.filter((origFile) => !subTaskFiles.some((file) => file.uuid === origFile.uuid))

    for (let i = 0; i < addedFiles.length; i++) {
      await api.put(`/tasks/${uuid}/sub/${subTask.uuid}/file`, addedFiles[i])
    }

    for (let i = 0; i < deletedFiles.length; i++) {
      await api.delete(`/tasks/${uuid}/sub/${subTask.uuid}/file/${deletedFiles[i].uuid}`)
    }

    // reset
    setSubTaskDescription("")
    setSubTaskSubDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskValueMin(null)
    setSubTaskValueMax(null)
    setSubTaskFiles([])
    setEditedSubTaskPosition(null)
    refresh()
  }

  const [history, setHistory] = useState([])

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [isLoading, setIsLoading] = useState(false)

  const { data: taskStates = [] } = useTaskStates()
  const getTaskStateName = () => taskStates.length && state
    ? taskStates.find((taskState) => taskState.value === state).name
    : ""

  const create = (isCloning) => {
    setIsLoading(true)

    const payload = {
      type: "PREVENTION",
      operation,
      name, description, repeated, team, machine,
      starts_at: moment(startsAt).seconds(0).toISOString(),
      order,
      tasks: subTasks.map((subTask) => {
        let newSubTask = Object.assign({}, subTask)

        if (newSubTask.value_min === null) {
          delete newSubTask.value_min
        }

        if (newSubTask.value_max === null) {
          delete newSubTask.value_max
        }

        newSubTask.files = newSubTask.files.map((subTaskFile) => {
          const newSubTaskFile = Object.assign({}, subTaskFile)

          if (!newSubTaskFile.url) {
            delete newSubTaskFile.uuid
          }

          return newSubTaskFile
        })

        return newSubTask
      }),
    }

    if (repeated === "DAILY_INTERVAL") {
      payload.repeated_value = repeatedValue
    }

    if (repeated === "DAYS") {
      payload.repeated_value = daysToNumber()
    }

    // cloning does not preserve files. request from customer...
    payload.files = isCloning
      ? []
      : files.map((file) => {
        const newFile = Object.assign({}, file)

        if (!newFile.url) {
          delete newFile.uuid
        }

        return newFile
      })

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    api
      .put("/tasks", payload)
      .then((response) => {
        usehistory.push(`/task/prevention/${response.data.data.uuid}/subtasks`)
      })
  }

  const close = () => api
    .patch(`/tasks/${uuid}/close`, {})
    .then(back)
  const closeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu uzavřít?", close)

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (subTasks.length === 0) {
      setSubTaskErrorColor(true)
      alert("Pravidelná údržba neobsahuje žádné podúkoly. Pokud chcete záznam uložit přidejte podúkol.")
      setTimeout(() => {
        setSubTaskErrorColor(false)
      }, 3000)
      return
    }

    const isCloning = e.nativeEvent.submitter.value === "save-as-new"

    if (uuid && !isCloning) {
      editConfirmation()
    } else {
      create(isCloning)
    }
  }
  const handleCloseAndFormSubmit = async () => {

    await api.patch(`/tasks/${uuid}/close`, {})
    create()
  }
  const editConfirmation = () => confirmation(refBtnSave.current,
    "Tato údržba se uzavře a bude vytvořena nová. Pokračovat?", handleCloseAndFormSubmit)

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)

          setState(data.data.state)
          setName(data.data.name)
          setDescription(data.data.description)
          setRepeated(data.data.repeated)
          if (data.data.repeated === "DAYS") {
            numberToDays(data.data.repeated_value)
          } else {
            setRepeatedValue(data.data.repeated_value || 1)
          }
          setStartsAt(moment(data.data.starts_at).toDate())

          setMachine(data.data.machine.uuid)
          setMFOperation(data.data.machine.place.operation.uuid) // set correct machine filters

          setOperation(data.data.operation ? data.data.operation.uuid : "")
          setTeam(data.data.team.uuid)
          setResponsibleUser(data.data.user ? data.data.user.uuid : "")
          setSomeSubTaskWithoutPosition(data.data.tasks.some((subTask) => !subTask.position))
          setSubTasks(() => {
            data.data.tasks.forEach((subTask, index) => {
              if (!subTask.position) {
                subTask.position = index + 1
              }
            })
            return data.data.tasks
          })
          setFiles(data.data.files || [])
          setHistory(data.data.history)
          setOrder(data.data.order || "")
        })
    }
  }, [uuid, refresher, setSubTasks])

  useEffect(() => {
    if (repeated === "YEARLY_PAR378") {
      setSubTasks(minimalRequirementsSubtaks)
    }
  }, [repeated, setSubTasks])

  if (hasRole("USER")) {
    return <Redirect to={"/tasks/preventions"} />
  }

  return (
    <>
      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label>Aktuální stav:</label>
            {task && task.last_not_finished ? (
              <span className="p-inputgroup">
                <InputText value={getTaskStateName(state)} disabled={true} />
                <span className="p-inputgroup-addon">
                  <NotFinishedInfo />
                </span>
              </span>
            ) : (
              <InputText value={getTaskStateName(state)} disabled={true} />
            )}
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Název:</label>
            <InputText required={true} value={name} onChange={handleNameChange} maxLength={30} />
            <small className={commonStyles.fieldHelp}>{30 - name.length} znaků zbývá.</small>
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Detailní popis:</label>
            <InputTextarea required={true} value={description} rows={5}
              onChange={handleDescriptionChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Opakování:</label>
            <Dropdown required={true} value={repeated} options={repeatedOptions}
              optionValue={"value"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
              onChange={handleRepeatedChange} />
          </div>

          {repeated === "DAILY_INTERVAL" && (
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Počet dní:</label>
              <InputText type={"number"} required={repeated === "DAILY_INTERVAL"}
                value={repeatedValue} min={1} onChange={handleRepeatedValueChange} />
              <small className={commonStyles.fieldHelp}>Opakovat
                každých {repeatedValue} dní.</small>
            </div>
          )}

          {repeated === "DAYS" && (
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Dny:</label>
              <MultiSelect options={dayOptions} value={days} onChange={onDaysChange}
                placeholder="Vyberte" maxSelectedLabels={7}
                required={repeated === "DAYS"} />
            </div>
          )}

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Začátek opakování:</label>
            <Calendar value={startsAt} dateFormat={"dd. mm. yy"} onChange={handleStartsAtChange}
              showTime={true} hourFormat={24} hideOnDateTimeSelect={true} />
          </div>

          <Fieldset className={"p-0 mb-3"}>
            <div className={"formgrid grid"}>
              <div className={"field col pl-0"}>
                <label>Provoz:</label>
                <Dropdown options={getMFOperationOptions()} value={MFOperation}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFOperationChange} />
              </div>

              <div className={"field col"}>
                <label>Místo:</label>
                <Dropdown options={getMFPlaceOptions()} value={MFPlace}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFPlaceChange} />
              </div>

              <div className={"field col pr-0"}>
                <label>Typ stroje:</label>
                <Dropdown options={getMFTypeOptions()} value={MFType}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFTypeChange} />
              </div>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroj:</label>
              <Dropdown required={true} value={machine} options={getMachineOptions()} optionValue={"uuid"}
                optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                filter={true} showClear={true} filterBy={"name"}
                onChange={handleMachineChange}
                itemTemplate={tMachine}
                valueTemplate={tMachine} />
            </div>
          </Fieldset>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Řeší provoz:</label>
            <Dropdown value={operation} options={operations}
              optionValue={"uuid"} optionLabel={"name"}
              emptyMessage={"Žádné záznamy"}
              onChange={onOperationChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Odpovědný tým:</label>
            <Dropdown required={true} value={team} options={getTeamOptions()} optionValue={"uuid"}
              optionLabel={"name"} emptyMessage={"Žádné záznamy"}
              onChange={handleTeamChange} />
          </div>

          <div className={"field"}>
            <label>Odpovědná osoba:</label>
            <Dropdown value={responsibleUser} options={getUserOptions()}
              optionValue={"uuid"} itemTemplate={userTemplate} optionLabel={"name"}
              emptyMessage={"Žádné záznamy"}
              onChange={handleResponsibleUserChange} />
          </div>

          <Accordion expandIcon={"pi pi-chevron-down"} collapseIcon={"pi pi-chevron-up"}>
            <AccordionTab header={"Zakázka"}
              headerClassName={commonStyles.accordionLabelHeader}
              contentClassName={commonStyles.accordionLabelContent}>
              <div className={"field"}>
                <InputText value={order} onChange={handleOrderChange} />
              </div>
            </AccordionTab>
          </Accordion>

          {repeated !== "YEARLY_PAR378" && (
            <>
              <Divider>Podúkoly:</Divider>
              {subTasks.length === 0 && <p>Žádné podúkoly.</p>}

              <ul className={"pl-4"}>
                {subTasks.map((subTask) => (
                  <li key={subTask.description}>
                    <div className={"flex align-items-center"}>
                      {subTask.description}

                      {subTask.value_description && (
                        <small className={"text-sm ml-1"}>
                          ({subTask.value_description}/{subTask.value_unit}{subTask.value_min ? `, min: ${subTask.value_min}` : ""}{subTask.value_max ? `, max: ${subTask.value_max}` : ""})
                        </small>
                      )}

                      <div className={"py-1 ml-auto"}>
                        <Button className={"p-button-text p-1"}
                          icon={"pi pi-arrow-up"}
                          tooltip={"Posunout o pozici výše"}
                          type={"button"}
                          disabled={someSubTaskWithoutPosition}
                          onClick={() => uuid
                            ? moveSubTaskUpAsync(subTask.position)
                            : moveSubTaskUp(subTask.position)} />

                        <Button className={"p-button-text p-1"}
                          icon={"pi pi-arrow-down"}
                          tooltip={"Posunout o pozici níže"}
                          type={"button"}
                          disabled={someSubTaskWithoutPosition}
                          onClick={() => uuid
                            ? moveSubTaskDownAsync(subTask.position)
                            : moveSubTaskDown(subTask.position)} />

                        <Button className={"p-button-text p-1"}
                          icon={"pi pi-pencil"}
                          tooltip={"Upravit"}
                          type={"button"}
                          onClick={() => handleEditSubTaskClick(subTask.position)} />

                        <Button className={"p-button-text p-1 p-button-danger"}
                          icon={"pi pi-times"}
                          tooltip={"Smazat"}
                          type={"button"}
                          onClick={() => removeSubTask(subTask.position)} />
                      </div>
                    </div>

                    <div><i>{subTask.sub_description}</i></div>

                    <div className={"flex flex-wrap"}>
                      {subTask.files && subTask.files
                        .filter((file) => file.createdBy !== "USER")
                        .map((file) => (
                          <FileThumb key={file.uuid} file={file} isLarge={false} />
                        ))}
                    </div>
                  </li>
                ))}
              </ul>

              <Divider className={`font-normal`}>
                <span style={subTaskErrorColor ? { color: "red" } : {}}>{editedSubTaskPosition ? "Aktualizovat" : "Nový"} podúkol:</span>
              </Divider>
              
              <div className={"field"}>
                <label>Název:</label>
                <InputText value={subTaskDescription} onChange={handleSubTaskDescriptionChange}
                  placeholder={"Název"} />
              </div>
              
              <div className={"field"}>
                <label>Popis:</label>
                <InputText value={subTaskSubDescription} onChange={handleSubTaskSubDescriptionChange}
                  placeholder={"Popis"} />
              </div>

              <div className={"field"}>
                <label>Sledovaná hodnota:</label>
                <div className={"p-inputgroup"}>
                  <InputText value={subTaskValueDescription}
                    onChange={handleSubTaskValueDescriptionChange} placeholder={"*Název"}
                  />
                  <InputText value={subTaskValueUnit} onChange={handleSubTaskValueUnitChange}
                    placeholder={"*Jednotka"}
                  />
                </div>
                <div className={"p-inputgroup mt-1"}>
                  <InputNumber value={subTaskValueMin}
                    onChange={handleSubTaskValueMinChange} placeholder="Minimum"
                    useGrouping={false} />
                  <InputNumber value={subTaskValueMax}
                    onChange={handleSubTaskValueMaxChange} placeholder="Maximum"
                    useGrouping={false} />
                </div>
                <small className={commonStyles.fieldHelp}>Zadejte pouze v případě, že chcete aby
                  pracovník vyplňoval proměnnou veličinu (např. velikost nákolku kola
                  jeřábu).</small>
              </div>

              <div className={"field"}>
                <FileUpload files={subTaskFiles} setFiles={setSubTaskFiles} showClear={true} />
              </div>
            </>
          )}
        </div>

        {repeated !== "YEARLY_PAR378" && (
          <Button className={"p-button-raised p-button-text ml-1 mb-3"}
            type={"button"}
            disabled={!subTaskDescription}
            label={editedSubTaskPosition ? "Aktualizovat podúkol" : "Přidat podúkol"}
            onClick={editedSubTaskPosition
              ? uuid
                ? editSubTaskAsync
                : editSubTask
              : addSubTask} />
        )}

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={uuid ? back : backConfirmation} />
          <div className={"ml-auto"}>
            <Button type={"button"} label={"Uzavřít"} className={"p-button-danger m-1"}
              onClick={closeConfirmation} disabled={!uuid || state !== "OPEN"}
              tooltip={"Uzavřením dojde k ukončení opakování pravidelné údržby."}
              tooltipOptions={{ position: 'top' }}
            />

            <NavLink to={`/task/prevention/${uuid}/subtasks`}
              className={`p-button m-1 ${uuid ? "" : "p-disabled"}`}>Zobrazit
              stav podúkolů</NavLink>

            <Button type={"submit"} label={"Uložit"} className={"m-1"} ref={refBtnSave}
              disabled={!hasPerm("ROLE_PERM_PREVENTION_WRITE")}
              tooltip={uuid ? "Původní údržba se uzavře a vytvoří se nová změněná. Výsledkem bude jedna uzavřená údržba v historii. A jedna nová aktivní údržba." : ""}
              tooltipOptions={{ position: 'top' }}
            />

            {uuid && (
              <Button type={"submit"} label={"Uložit novou"} className={"m-1"}
                icon={isLoading ? "pi pi-spin pi-spinner" : ""}
                disabled={isLoading || !hasPerm("ROLE_PERM_PREVENTION_WRITE")}
                value={"save-as-new"}
                tooltip={"Původní údržba se zachová a vytvoří se nová změněná. Výsledkem budou 2 aktivní údržby."}
                tooltipOptions={{ position: 'top' }}
              />
            )}
          </div>
        </div>
      </form>

      <div className={"col-12 md:col-6"}>
        <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
          entityUrl={uuid ? `/tasks/${uuid}` : ""}
          isDisabled={!hasPerm("ROLE_PERM_PREVENTION_WRITE")} />
        {uuid && (
          <>
            <TaskHistory history={history} taskType={"prevention"} />
            <WarehouseItemForm taskUuid={uuid} onChange={refresh} taskType={"prevention"} />
          </>
        )}
      </div>
    </>
  )
}
