import { useContext, useEffect, useState } from "react"
import { useParams, useHistory, NavLink } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Badge } from "primereact/badge"
import { Divider } from "primereact/divider"
import moment from "moment"
import { useImmer } from "use-immer"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useTaskStates } from "../../../api/queries/useTaskStates"
import getSubTaskStateName from "../../../function/getSubTaskStateName"
import { useSubTaskCauses } from "../../../api/queries/useSubTaskCauses"
import commonStyles from "../../common.module.css"
import FileUpload from "../../File/FileUpload"
import { useTaskTags } from "../../../api/queries/useTaskTags"
import getSubTaskStateSeverity from "../../../function/getSubTaskStateSeverity"
import { NotFinishedInfo } from "./NotFinishedInfo"
import { formatMachineName } from "../../../function/machine"
import styles from "./styles.module.css"
import { useTaskRepeated } from "../../../api/queries/useTaskRepeated"
import { Tooltip } from "primereact/tooltip"

export default function TaskPreventionSubTaskForm() {
  const { uuid } = useParams()
  const { user } = useContext(userContext)
  const history = useHistory()
  const back = () => history.goBack()
  const backToForm = () => history.push(`/task/prevention/${uuid}`)
  const { data: taskStates = [] } = useTaskStates()
  const { data: taskTags = [] } = useTaskTags()
  const { data: taskRepeatedOptions = [] } = useTaskRepeated()
  const zavadaTag = taskTags.find((taskTag) => taskTag.key === "zavada")
  const { data: subTaskCauses = [] } = useSubTaskCauses()
  const subTaskCausesFiltered = subTaskCauses
    .filter((subTaskCause) => subTaskCause.value !== "FAULT")
    .filter((subTaskCause) => !subTaskCause.disabled)

  const tSubTaskCauseItem = (option) => {
    return `${option.name} - ${option.description}`
  }
  const tSubTaskCauseValue = (option, props) => {
    if (option) {
      return `${option.name} - ${option.description}`
    }

    return <span>{props.placeholder || ""}</span>
  }

  const [task, setTask] = useState({
    machine: { name: "" },
  })
  const [refresher, setRefresher] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [originalFileUuids, setOriginalFileUuids] = useState([])

  const [subTaskOpen, setSubTaskOpen] = useState({})
  const [subTaskOpenState, setSubTaskOpenState] = useState("")
  const [subTaskOpenCause, setSubTaskOpenCause] = useState(null)
  const [subTaskOpenReportName, setSubTaskOpenReportName] = useState("")
  const [subTaskOpenReportDesc, setSubTaskOpenReportDesc] = useState("")
  const [subTaskOpenReportFiles, setSubTaskOpenReportFiles] = useState([])

  const handleSubTaskOpenCauseChange = (e) => setSubTaskOpenCause(e.target.value)
  const handleSubTaskOpenReportNameChange = (e) => setSubTaskOpenReportName(e.target.value)
  const handleSubTaskOpenReportDescChange = (e) => setSubTaskOpenReportDesc(e.target.value)

  const openSubTask = (subTask, state) => {
    setSubTaskOpen(subTask)
    setSubTaskOpenState(state)
    setSubTaskOpenReportDesc("")

    if (state === "FINISHED") {
      setSubTaskOpenCause("FAULT")
    } else {
      setSubTaskOpenCause("")
    }
  }

  const closeSubTask = () => {
    setSubTaskOpen({})
    setSubTaskOpenCause(null)
    setSubTaskOpenState("")
    setSubTaskOpenReportName("")
    setSubTaskOpenReportDesc("")
    setSubTaskOpenReportFiles([])
  }

  const [relatedTasks, setRelatedTasks] = useState([])
  const [subTasks, setSubTasks] = useImmer([])

  const handleSubTaskChange = (subTask, key, value) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)

      if (key === "value") {
        value = parseFloat(value)
      }

      draftSubTask[key] = value
    })
  }

  const setSubTaskFiles = async (subTask, files) => {
    if (subTask.files.length > files.length) {
      // some file was deleted
      let deletedFileUuid = null

      subTask.files.forEach((subTaskFile) => {
        const file = files.find((file) => subTaskFile.uuid === file.uuid)

        // async delete only for existing files (they have url property)
        if (subTaskFile.url && file === undefined) {
          deletedFileUuid = subTaskFile.uuid
        }
      })

      if (deletedFileUuid) {
        await api.delete(`/tasks/${uuid}/sub/${subTask.uuid}/file/${deletedFileUuid}`)
      }
    }

    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)
      draftSubTask.files = [...files]
    })
  }

  const updateSubtask = async (subTask, state) => {
    setIsLoading(true)

    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })

    const params = {
      uuid: subTask.uuid, state, state_description: subTask.state_description,
      files: subTask.files,
    }

    if (subTaskOpenCause) {
      params.cause_of_unfinished = subTaskOpenCause
    }

    if (subTask.value) {
      params.value = subTask.value
    }

    await api.post(`/tasks/${uuid}/sub/${subTask.uuid}`, params)

    // new files
    const files = subTask.files.filter((file) => !file.createdBy)

    for (let i = 0; i < files.length; i++) {
      const file = Object.assign({}, files[i])
      delete file.uuid
      await api.put(`/tasks/${uuid}/sub/${subTask.uuid}/file`, file)
    }

    setRefresher(!refresher)
    setIsLoading(false)
    closeSubTask()
  }

  const reopenSubtask = async (subTask) => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })
    await api.post(`/tasks/${uuid}/sub/${subTask.uuid}`, {
      uuid: subTask.uuid,
      state: "UNKNOWN",
    })
    setRefresher(!refresher)
  }

  const finishPrevention = async () => {
    let state = "FINISHED"

    if (subTasks.some((subTask) => subTask.state === "NOT_FINISHED")) {
      state = "PARTIALY_FINISHED"
    }

    if (subTasks.some((subTask) => subTask.state === "UNKNOWN")) {
      state = "NOT_FINISHED"
    }

    await api.post(`/tasks/${uuid}`, { uuid, state })
    setRefresher(!refresher)
  }

  const createReport = async (subTask) => {
    const files = subTaskOpenReportFiles.map((file) => {
      const newfile = Object.assign({}, file)
      delete newfile.uuid
      return newfile
    })

    const resp = await api.put("/tasks", {
      type: "REPORT",
      operation: user.operation.uuid,
      team: task.team.uuid,
      machine: task.machine.uuid,
      starts_at: moment().toISOString(), // now
      name: subTaskOpenReportName,
      description: subTaskOpenReportDesc,
      files,
      tag: subTaskOpenCause === "FAULT" ? zavadaTag.uuid : "",
    })
    await api.post(`/tasks/${uuid}/related`, {
      uuid: resp.data.data.uuid,
      subtask_uuid: subTask.uuid,
      is_child: false
    })
    await updateSubtask(subTask, subTaskOpenState)
  }

  const getTaskStateName = () => taskStates.length && task.state
    ? taskStates.find((taskState) => taskState.value === task.state).name
    : ""

  const getSubTaskBadgeValue = (subTask) => {
    const subTaskStateName = getSubTaskStateName(subTask.state)
    const subTaskCause = subTaskCauses.find((cause) => cause.value === subTask.cause_of_unfinished)

    if (subTaskCause) {
      return `${subTaskStateName} (${subTaskCause.name})`
    }

    return subTaskStateName
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
          setRelatedTasks(data.data.related)
          setSubTasks((draft) => {
            draft.splice(0, draft.length)
            data.data.tasks.forEach((subTask) => draft.push(subTask))
          })

          // handle original files from prevention, this should not be deleted by user
          const preventionFileUuids = []

          data.data.tasks.forEach((subTask) => {
            subTask.files.forEach((file) => {
              preventionFileUuids.push(file.uuid)
            })
          })

          setOriginalFileUuids(preventionFileUuids)
        })
    }
  }, [uuid, refresher, setSubTasks])


  const renderRelatedSubTaskDetail = (uuid) => {
    const subTaskRelatedTasks = relatedTasks.filter((relatedTask) => relatedTask.related_subtask_uuid === uuid)

    if (subTaskRelatedTasks.length > 0) {
      return (
        <div className={"pb-3"}>
          <label className="text-500">Existující hlášení:</label>
          {" "}
          {subTaskRelatedTasks.map((r, i) => (
            <>
              <NavLink
                to={`/task/report/${r.uuid}/detail`}
                className={"text-primary"}
                target="_blank"
              >
                #{r.id} - {r.name}
              </NavLink>

              {i < subTaskRelatedTasks.length - 1 && <>, </>}
            </>
          ))}
        </div>
      )
    } else {
      return <></>
    }
  }

  const getRepeated = () => {
    if (task.repeated) {
      const repeated = taskRepeatedOptions.find(repeated => repeated.value === task.repeated)

      if (repeated) {
        if (task.repeated_value) {
          return `${repeated.name} (${task.repeated_value})`
        }

        return repeated.name
      }
    }

    return ""
  }

  return (
    <userContext.Consumer>
      {({ hasPerm, hasRole, isAdmin }) => (
        <div className={"col-12 md:col-6"}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label>Aktuální stav pravidelné údržby:</label>
              {task.last_not_finished ? (
                <span className="p-inputgroup">
                  <InputText value={getTaskStateName()} disabled={true} />
                  <span className="p-inputgroup-addon">
                    <NotFinishedInfo />
                  </span>
                </span>
              ) : (
                <InputText value={getTaskStateName()} disabled={true} />
              )}
            </div>

            <div className={"field"}>
              <label>Název:</label>
              <InputText value={task.name || ""} disabled={true} />
            </div>

            <div className={"field"}>
              <label>Opakování:</label>
              <InputText value={getRepeated()} disabled={true} />
            </div>

            <div className={"field"}>
              <label>Stroj:</label>
              <InputText value={task.machine ? formatMachineName(task.machine) : ""} disabled={true} />
            </div>

            <div className={"field"}>
              <label>Detailní popis:</label>
              <div className={styles.fieldLike}>
                {task.description || ""}
              </div>
            </div>

            {subTasks.map((subTask, i) => (
              <div key={subTask.uuid}>
                <Divider>
                  <Badge className={"mr-1"}
                    value={getSubTaskBadgeValue(subTask)}
                    severity={getSubTaskStateSeverity(subTask)} />
                  <label>Podúkol č. {i + 1}: <i className={"my-2"}>{subTask.description}</i></label>
                </Divider>

                {subTask.sub_description && (
                  <div className="mb-3"><i>{subTask.sub_description}</i></div>
                )}

                {subTask.value_description && (
                  <div className={"field"}>
                    <label className={commonStyles.labelRequired}>
                      {subTask.value_description} ({subTask.value_unit}{subTask.value_min ? `, min: ${subTask.value_min}` : ""}{subTask.value_max ? `, max: ${subTask.value_max}` : ""})
                    </label>
                    <InputText placeholder={subTask.value_description} type={"number"}
                      value={subTask.value || ""} disabled={subTask.state !== "UNKNOWN"}
                      onChange={(e) => handleSubTaskChange(subTask, "value", e.target.value)} />
                  </div>
                )}

                <div className={"field"}>
                  <label>Poznámka:</label>
                  <div className={"p-inputgroup"}>
                    <InputText value={subTask.state_description || ""}
                      placeholder={`Poznámka k podúkolu č. ${i + 1}`}
                      disabled={subTask.state !== "UNKNOWN"}
                      onChange={(e) => handleSubTaskChange(subTask, "state_description", e.target.value)} />

                    {subTask.state === "UNKNOWN"
                      && hasPerm("ROLE_PERM_PREVENTION_WRITE")
                      && (
                        <>
                          <Tooltip target=".t1" />
                          <span className="t1 flex" data-pr-tooltip="Úspěšně splněno." data-pr-position="top">
                            <Button type={"button"}
                              className={"p-button-success"} icon={"pi pi-check"}
                              onClick={() => updateSubtask(subTask, "FINISHED")}
                              disabled={
                                isLoading ||
                                (subTask.value_description && !subTask.value) ||
                                (subTask.value && subTask.value_min && subTask.value < subTask.value_min) ||
                                (subTask.value && subTask.value_max && subTask.value > subTask.value_max)
                              }
                              style={{ borderRadius: 0 }}
                            />
                          </span>

                          <Tooltip target=".t2" />
                          <span className="t2 flex" data-pr-tooltip="Úspěšně splněno s nalezenou závadou. Vytváří se hlášení." data-pr-position="top">
                            <Button type={"button"}
                              className={"p-button-warning"} icon={"pi pi-exclamation-circle"}
                              onClick={() => openSubTask(subTask, "FINISHED")}
                              disabled={(subTask.value_description && !subTask.value)}
                              style={{ borderRadius: 0 }}
                            />
                          </span>

                          <Tooltip target=".t3" />
                          <span className="t3 flex" data-pr-tooltip="Úkol nelze dokončit. Volí se důvod nedokončení." data-pr-position="top">
                            <Button type={"button"}
                              className={"p-button-danger"} icon={"pi pi-times"}
                              onClick={() => openSubTask(subTask, "NOT_FINISHED")}
                              disabled={isLoading} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            />
                          </span>
                        </>
                      )}

                    {task.state === "IN_PROGRESS"
                      && subTask.state !== "UNKNOWN"
                      && (
                        <Button type={"button"} title={"Znovu otevřít podúkol"}
                          className={"p-button-primary"} icon={"pi pi-refresh"}
                          onClick={() => reopenSubtask(subTask)} />
                      )}
                  </div>

                  <div className={"field mt-3"}>
                    {(subTask.files.length > 0 || subTask.state === "UNKNOWN") && (
                      <label>Přílohy k dokončení podúkolu:</label>
                    )}

                    <FileUpload files={subTask.files}
                      setFiles={(files) => setSubTaskFiles(subTask, files)}
                      disabled={subTask.state !== "UNKNOWN"}
                      showClear={subTask.state === "UNKNOWN"}
                      originalFileUuids={originalFileUuids} />
                  </div>

                  {subTask.uuid === subTaskOpen.uuid && (
                    <>
                      {subTaskOpenState === "NOT_FINISHED" && (
                        <>
                          <Divider>Důvod nedokončení</Divider>

                          <div className={"field"}>
                            <label className={commonStyles.labelRequired}>Kategorie:</label>
                            <Dropdown options={subTaskCausesFiltered} value={subTaskOpenCause}
                              onChange={handleSubTaskOpenCauseChange} optionLabel={"name"}
                              optionValue={"value"} placeholder={"Kategorie"}
                              disabled={subTask.state === "NOT_FINISHED"}
                              itemTemplate={tSubTaskCauseItem}
                              valueTemplate={tSubTaskCauseValue} />
                          </div>

                          <Button type={"button"}
                            className={"p-button-danger"}
                            label={"Dokončit podúkol"}
                            disabled={!subTaskOpenCause}
                            onClick={() => updateSubtask(subTask, "NOT_FINISHED")} />
                        </>
                      )}

                      {subTaskOpenState === "FINISHED" && (
                        <>
                          <Divider>Hlášení</Divider>

                          <div className={"field"}>
                            <label className={commonStyles.labelRequired}>Název:</label>
                            <InputText value={subTaskOpenReportName} placeholder={"Název"}
                              onChange={handleSubTaskOpenReportNameChange} maxLength={30} />
                          </div>
                          <div className={"field"}>
                            <label className={commonStyles.labelRequired}>Popis:</label>
                            <InputText value={subTaskOpenReportDesc} placeholder={"Popis"}
                              onChange={handleSubTaskOpenReportDescChange} />
                          </div>
                          <div className={"field"}>
                            <FileUpload files={subTaskOpenReportFiles}
                              setFiles={setSubTaskOpenReportFiles}
                              showClear={true} />
                          </div>

                          <Button type={"button"}
                            className={"p-button-warning"}
                            label={"Dokončit podúkol a vytvořit hlášení"}
                            disabled={!subTaskOpenReportName || !subTaskOpenReportDesc}
                            onClick={() => createReport(subTask)} />
                        </>
                      )}
                    </>
                  )}
                </div>
                {renderRelatedSubTaskDetail(subTask.uuid)}
              </div>
            ))}
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
              onClick={back} />

            {(isAdmin() || hasRole("SUPERVISOR")) && (
              <Button type={"button"} label={"Přehled"} className={"p-button-warning m-1"}
                onClick={backToForm} />
            )}

            {!hasRole("USER") &&
              hasPerm("ROLE_PERM_PREVENTION_WRITE") &&
              task.state === "IN_PROGRESS" &&
              !subTasks.some((subTask) => subTask.state === "UNKNOWN") && (
                <Button type={"button"} className={"p-button-primary m-1"}
                  onClick={finishPrevention} label={"Dokončit údržbu"} />
              )}
          </div>
        </div >
      )
      }
    </userContext.Consumer >
  )
}
